import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { encodeId } from '../HelperMethods';
import PhotoMethods from '../HelperMethods';

const SONG = 'SONG';
const ARTIST = 'ARTIST';
const ALBUM = 'ALBUM';
const IDOL = 'IDOL';

function SearchBar() {
  const [artists, setArtists] = useState(null);
  const [albums, setAlbums] = useState(null);
  const [songs, setSongs] = useState(null);
  const navigate = useNavigate();
  var updated_results = [];

  useEffect(() => {
    const fetchArtists = async () => {
      const response = await fetch('/api/artists/search');
      const json = await response.json();

      if (response.ok) {
        setArtists(json);
      }
    };

    const fetchAlbums = async () => {
      const response = await fetch('/api/albums/search');
      const json = await response.json();

      if (response.ok) {
        setAlbums(json);
      } else {
        console.log(response.status + ' - ' + json.error);
      }
    };

    const fetchSongs = async () => {
      const response = await fetch('/api/songs/search');
      const json = await response.json();

      if (response.ok) {
        setSongs(json);
      }
    };

    fetchArtists();
    fetchAlbums();
    fetchSongs();
  }, []);

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log(string, results);

    if (string.length > 0) {
      updated_results = results;
      // console.log('hi', updated_results);
    }

    // Detect Enter key within this handler
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        // console.log(string, results, updated_results);
        // Perform a general search if no results
        navigate(`/search?query=${string}`, { state: { results: updated_results } });
      }
    };
    // Attach keydown event listener to the window
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener when component is unmounted or when `onSearch` is called again
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    if (item.type.includes(ARTIST) || item.type.includes(IDOL)) {
      navigate(`/artist/${encodeId(item.id)}`, {
        state: { id: item.id }
      });
    } else if (item.type.includes(ALBUM)) {
      navigate(`/album/${encodeId(item.id)}`, {
        state: { id: item.id }
      });
    } else if (item.type.includes(SONG)) {
      navigate(`/song/${encodeId(item.id)}`, {
        state: { song_id: item.id }
      });
    }

    window.location.reload();
  };

  const handleOnFocus = () => {
    console.log('Focused');
  };

  const formatResult = (item) => {
    return (
      <div className="search-results-dropdown">
        <img
          className="search-photo"
          src={PhotoMethods(item.picture_url)}
          style={{
            'max-width': '50px',
            'padding-right': '1em',
            'box-shadow': '0'
          }}
          onError={(event) => {
            event.target.src = '/dubu_face.jpg';
            event.onerror = null;
          }}></img>

        <span
          className="search-result"
          style={{ width: '100%', display: 'flex', textAlign: 'left', overflow: 'scroll' }}>
          <h8 className="search-result-type">
            {item.type}{' '}
            {(item.type.includes(ALBUM) || item.type.includes(SONG)) &&
              ' BY ' + item?.artist_english_name}
          </h8>{' '}
          <div className="search-name">{item.name}</div>
        </span>
      </div>
    );
  };

  return (
    <div className="search-bar-container">
      <header className="search-bar-header">
        {/* <div style={{ width: '15em' }}> */}
        {artists && songs && (
          <ReactSearchAutocomplete
            items={artists.concat(albums).concat(songs)}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            formatResult={formatResult}
            // maxResults={20}
          />
        )}
        {/* </div> */}
      </header>
    </div>
  );
}

export default SearchBar;
