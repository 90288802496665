import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';
import { Link } from 'react-router-dom';
// import HomeCarouselCard from 'components/HomeCarouselCard';

const data = [
  {
    image: require('../images/20241014_jennie_mobile.jpeg'),
    caption: 'jennie',
    description: 'this that prettygirl mantra',
    album: 23758532
  },
  {
    image: require('../images/20241014_karina_mobile.jpeg'),
    caption: 'karina (aespa solos)',
    description: 'spice it up, up, up',
    album: 23755140
  },
  {
    image: require('../images/20240901_le_sserafim_mobile.jpeg'),
    caption: 'le sserafim',
    description: 'act like an angel & dress like crazy',
    album: 23708548
  },
  {
    image: require('../images/20240907_meovv_mobile.jpeg'),
    caption: 'meovv',
    description: "we'll make 'em meow",
    album: 23718680
  }
];

function HomeCarouselMobile() {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="carousel">
      {data.map((slide, idx) => {
        return (
          <Carousel.Item key={idx}>
            <img
              className="carousel-home"
              loading="lazy"
              src={slide.image}
              alt="banner of the latest kpop releases"
            />
            <Link to={`/album/${slide.album}`}>
              <Carousel.Caption>
                <h3 style={{ 'font-size': '300%' }}>{slide.caption}</h3>
                <p>{slide.description}</p>
              </Carousel.Caption>
            </Link>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
export default HomeCarouselMobile;
